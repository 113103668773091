import { render, staticRenderFns } from "./account-creator.vue?vue&type=template&id=04df2b71&scoped=true&"
import script from "./account-creator.vue?vue&type=script&lang=js&"
export * from "./account-creator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04df2b71",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VForm,VSelect,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/Users/nickray/Projects/curate-web/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04df2b71')) {
      api.createRecord('04df2b71', component.options)
    } else {
      api.reload('04df2b71', component.options)
    }
    module.hot.accept("./account-creator.vue?vue&type=template&id=04df2b71&scoped=true&", function () {
      api.rerender('04df2b71', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/proposals/components/editors/account-creator.vue"
export default component.exports