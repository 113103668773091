<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitMethod"
  >
    <v-text-field
      autofocus
      v-model="firstName"
      label="First Name"
      :error-messages="errors.firstName"
      :rules="[v => rules.required(v) || 'First Name is required']"
    />
    <v-text-field
      v-model="lastName"
      label="Last Name"
      :error-messages="errors.lastName"
      :rules="[v => rules.required(v) || 'Last Name is required']"
    />
    <v-text-field
      v-model="email"
      label="Email"
      type="email"
      :rules="[v => rules.email(v) || 'Invalid email']"
      :error-messages="errors.email"
      @blur="() => checkForAvailability('email')"
      :loading="emailLoading"
    >
      <template v-slot:append>
        <a
          v-if="showSignInLink"
          :href="getSignInLink()"
        >
          Already Registered? Sign In
        </a>
      </template>
    </v-text-field>
    <v-text-field
      v-model="password"
      type="password"
      label="Password"
      :error-messages="errors.password"
      :rules="[v => rules.required(v) || 'Password is required']"
    />
    <v-text-field
      v-model="confirmPassword"
      type="password"
      label="Confirm Password"
      :error-messages="errors.confirmPassword"
      :rules="[
          v => rules.required(v) || 'Password must be confirmed',
          v => rules.matches(v, this.password) || 'Passwords must match'
        ]"
    />
    <v-select
      v-model="company.professional_types"
      :items="industries"
      label="Industry"
      chips
      multiple
    ></v-select>
    <v-text-field
      v-if="otherIsChecked"
      v-model="company.other_professional_type"
      class="px-4 pb-4"
      label="Please Specify Other Industry"
      prepend-icon="fa-industry"
      dense
    ></v-text-field>
    <v-text-field
      v-model="company.name"
      label="Company Name"
      @input="autoGenerateSubdomain"
      @blur="() => checkForAvailability('subDomain')"
      :error-messages="errors.companyName"
      :rules="[v => rules.required(v) || 'Company Name is required']"
    />
    <v-autocomplete
        v-model="company.currency"
        label="Currency"
        :items="supportedCurrencies"
        :error-messages="errors.companyCurrency"
        :rules="[v => rules.required(v) || 'Currency is required']"
    />
    <vuetify-google-autocomplete
      id="map"
      label="Physical Address"
      @placechanged="getPhysicalAddressData"
      :value="company.physical_address"
      hint="Not shown on proposal"
      :error-messages="errors.physicalAddress"
      :rules="[v => rules.required(v) || 'Company Address is required']"
    />
    <v-textarea
      v-model="company.heard_about_us"
      auto-grow
      outlined
      rows="1"
      label="Where Did You Hear About Us"
      :error-messages="errors.hearAboutUs"
      :rules="[v => rules.required(v) || 'Please fill out this field']"
    />
    <v-text-field
      v-model="company.subdomain"
      label="Account Link"
      :error-messages="errors.subDomain"
      @blur="() => checkForAvailability('subDomain')"
      :rules="[
          v => rules.required(v) || 'Please fill out this field',
          v => rules.subdomain(v) || 'Must start with a letter and contain only letters, numbers, and dashes'
      ]"
      :loading="subDomainLoading"
      suffix=".app.curate.co"
    />
    <hidden-submit-button />
  </v-form>
</template>

<script>
import kebabCase from 'lodash-es/kebabCase';
import HiddenSubmitButton from '@/components/common/HiddenSubmitButton.vue';
import { beautifyCamelCase } from '@/utils/misc';
import { currencyList } from '@/utils/formatting/pricing';
import CHECK_EMAIL from '@/graphql/Companies/EmailCheck.gql';
import CHECK_SUBDOMAIN from '@/graphql/Companies/SubDomainCheck.gql';
import * as validation from '@/utils/validation';

export default {
  name: 'AccountCreator',

  components: {
    HiddenSubmitButton,
  },

  data() {
    return {
      rules: validation,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      supportedCurrencies: currencyList,
      company: {
        professional_types: [],
        other_professional_type: '',
        name: '',
        currency: 'USD',
        address: '',
        address2: '',
        address_place_id: '',
        address_lat: '',
        address_lng: '',
        address_street_number: '',
        address_route: '',
        address_locality: '',
        address_postal_code: '',
        address_area_1: '',
        address_area_2: '',
        address_country: '',
        address_subpremise: '',
        physical_address: '',
        physical_address2: '',
        physical_address_place_id: '',
        physical_address_lat: '',
        physical_address_lng: '',
        physical_address_street_number: '',
        physical_address_route: '',
        physical_address_locality: '',
        physical_address_postal_code: '',
        physical_address_area_1: '',
        physical_address_area_2: '',
        physical_address_country: '',
        physical_address_subpremise: '',
        heard_about_us: '',
        phone: '',
        subdomain: '',
        time_zone_id: '',
        website: '',
      },
      errors: {
        firstName: [],
        lastName: [],
        email: [],
        password: [],
        confirmPassword: [],
        companyName: [],
        companyCurrency: [],
        physicalAddress: [],
        hearAboutUs: [],
        subDomain: [],
      },
      loading: true,
      subDomainLoading: false,
      emailLoading: false,
      valid: true,
      skipDomainQuery: true,
      skipEmailQuery: true,
      industries: [
        { text: 'Floral', value: 'floral' },
        { text: 'Food and Bev (Prep Items)', value: 'fandb' },
        { text: 'Rentals', value: 'rentals' },
        { text: 'Coordination', value: 'coordination' },
        { text: 'Other', value: 'other' },
      ],
    };
  },

  mounted() {
    this.email = this.proposal.owner.user_email || '';

    for (const key of Object.keys(this.proposal.company)) {
      const proposalValue = this.proposal.company[key];

      if (key in this.company && !!proposalValue) {
        this.company[key] = proposalValue;
      }
    }
    this.company.subdomain = kebabCase(this.company.name);

    this.loading = false;
  },

  apollo: {
    subDomainAvailable: {
      query: CHECK_SUBDOMAIN,
      variables() {
        return {
          subDomain: this.company.subdomain,
        };
      },
      skip() {
          return this.skipDomainQuery;
      },
    },
    emailAvailable: {
      query: CHECK_EMAIL,
      variables() {
        return {
          email: this.email,
        };
      },
      skip() {
          return this.skipEmailQuery;
      },
    },
  },

  computed: {
    otherIsChecked() {
      return this.company.professional_types.includes('other');
    },

    proposal() {
      return this.$store.state.proposals.current;
    },

    saveBtnEnabled() {
      let noErrors = true;

      Object.keys(this.errors).forEach(key => {
        noErrors = this.errors[key].length > 0 ? false : noErrors;
      });

      return noErrors;
    },

    showSignInLink() {
      // We check for false specifically as emailAvailable starts
      // as undefined and the response will be either true or false
      return !this.user && (this.emailAvailable === false);
    },

    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    async checkForAvailability(type) {
      const beautifiedType = beautifyCamelCase(type);
      const displayString = beautifiedType === 'Sub Domain' ? 'URL' : beautifiedType;
      const dataKey = `${type}Available`;
      const loadingKey = `${type}Loading`;

      this[loadingKey] = true;

      this.$apollo.queries[dataKey].skip = false;
      await this.$apollo.queries[dataKey].refetch();
      this.$apollo.queries[dataKey].skip = true;

      this.errors[type] = this[dataKey] ? [] : [ `${displayString} not available` ];
      this[loadingKey] = false;
    },

    getPhysicalAddressData(addressComponents, { formatted_address }) {
      this.company.physical_address = formatted_address;

      this.company.physical_address_place_id = addressComponents.place_id;
      this.company.physical_address_lat = addressComponents.latitude;
      this.company.physical_address_lng = addressComponents.longitude;
      this.company.physical_address_street_number = addressComponents.street_number;
      this.company.physical_address_route = addressComponents.route;
      this.company.physical_address_locality = addressComponents.locality;
      this.company.physical_address_postal_code = addressComponents.postal_code;
      this.company.physical_address_area_1 = addressComponents.administrative_area_level_1;
      this.company.physical_address_area_2 = addressComponents.administrative_area_level_2;
      this.company.physical_address_country = addressComponents.country;
      this.company.physical_address_subpremise = addressComponents.subpremise ? addressComponents.subpremise : '';
    },

    async save(close) {
      this.loading = true;
      this.$emit('saving', true);

      if ( !this.validate() ) {
        this.loading = false;
        this.$emit('saving', false);
        return;
      }

      let formData = new FormData();
      formData.append('user_first_name', this.firstName);
      formData.append('user_last_name', this.lastName);
      formData.append('user_email', this.email);
      formData.append('user_password', this.password);
      formData.append('user_password_confirmation', this.confirmPassword);

      for (const key of Object.keys(this.company)) {
        //skip 'professional_types'
        if (key === 'professional_types') {
          continue;
        }

        formData.append(`company_${key}`, this.company[key]);
      }

      this.company.professional_types.forEach(type =>{
        formData.append('company_professional_types[]', type);
      });

      return fetch(`${process.env.VUE_APP_CU_API_URL}json/users/create-freemium-user`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      }).then(res => res.json()).then(json => {
        if ('success' in json && !json.success) {
          return this.handleValidation(json.message);
        }

        return this.$store.dispatch('auth/authenticate', { newUser: true }).then(() => {
          this.$emit('complete', 'account-creator');

          this.loading = false;
          this.$emit('saving', false);

          this.resetValidation();

          this.$store.dispatch('save').then(({ data }) => {
            // We set this so that when we send the email, we have the correct id and not the template id
            this.$store.commit('updateField', { field: 'id', value: data.data.createdEventId });
          });

          close();
        }).catch(this.catchError);

      }).catch(this.catchError);
    },

    handleValidation(errors) {
      this.errors.companyName = [];
      this.errors.email = [];
      this.errors.password = [];
      this.errors.firstName = [];
      this.errors.lastName = [];
      this.errors.hearAboutUs = [];

      for (const key of Object.keys(errors)) {
        switch (key) {
          case 'company_name': {
            this.errors.companyName.push(errors[key]);
            break;
          }
          case 'user_email': {
            this.errors.email.push(errors[key]);
            break;
          }
          case 'user_password': {
            this.errors.password.push(errors[key]);
            break;
          }
          case 'user_first_name': {
            this.errors.firstName.push(errors[key]);
            break;
          }
          case 'user_last_name': {
            this.errors.lastName.push(errors[key]);
            break;
          }
          case 'heard_about_us': {
            this.errors.hearAboutUs.push(errors[key]);
            break;
          }
          case 'error_message': {
            this.catchError(errors[key]);
            break;
          }
        }
      }
    },

    catchError(message) {
      this.loading = false;
      this.$emit('saving', false);

      this.$store.commit('notify', {
        color: 'error',
        message,
        timeout: 5000,
      });
    },

    getSignInLink() {
      return `${process.env.VUE_APP_CU_APP_URL}logon?redirect_to=${encodeURIComponent(window.location.href)}`;
    },

    reset () {
      return this.$refs.form.reset();
    },

    resetValidation () {
      if (!('form' in this.$refs)) {
        return;
      }

      return this.$refs.form.resetValidation();
    },

    validate () {
      return this.$refs.form.validate();
    },

    submitMethod() {
      if (this.validate() && this.saveBtnEnabled) {
        this.$emit('enterPress');
      }
    },

    autoGenerateSubdomain() {
      let subdomain = this.company.name.toLowerCase();
      subdomain = subdomain.replace(/[^a-z0-9-]/gi, '-');
      subdomain = subdomain.replace(/-{2,}/g, '-');
      subdomain = subdomain.replace(/^-+/g, '');
      subdomain = subdomain.replace(/-+$/g, '');
      this.company.subdomain = subdomain;
    },
  },
};
</script>

<style scoped>
</style>
