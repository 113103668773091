var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitMethod($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          autofocus: "",
          label: "First Name",
          "error-messages": _vm.errors.firstName,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "First Name is required"
            }
          ]
        },
        model: {
          value: _vm.firstName,
          callback: function($$v) {
            _vm.firstName = $$v
          },
          expression: "firstName"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: "Last Name",
          "error-messages": _vm.errors.lastName,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "Last Name is required"
            }
          ]
        },
        model: {
          value: _vm.lastName,
          callback: function($$v) {
            _vm.lastName = $$v
          },
          expression: "lastName"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: "Email",
          type: "email",
          rules: [
            function(v) {
              return _vm.rules.email(v) || "Invalid email"
            }
          ],
          "error-messages": _vm.errors.email,
          loading: _vm.emailLoading
        },
        on: {
          blur: function() {
            return _vm.checkForAvailability("email")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _vm.showSignInLink
                  ? _c("a", { attrs: { href: _vm.getSignInLink() } }, [
                      _vm._v(" Already Registered? Sign In ")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.email,
          callback: function($$v) {
            _vm.email = $$v
          },
          expression: "email"
        }
      }),
      _c("v-text-field", {
        attrs: {
          type: "password",
          label: "Password",
          "error-messages": _vm.errors.password,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "Password is required"
            }
          ]
        },
        model: {
          value: _vm.password,
          callback: function($$v) {
            _vm.password = $$v
          },
          expression: "password"
        }
      }),
      _c("v-text-field", {
        attrs: {
          type: "password",
          label: "Confirm Password",
          "error-messages": _vm.errors.confirmPassword,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "Password must be confirmed"
            },
            function(v) {
              return (
                _vm.rules.matches(v, this$1.password) || "Passwords must match"
              )
            }
          ]
        },
        model: {
          value: _vm.confirmPassword,
          callback: function($$v) {
            _vm.confirmPassword = $$v
          },
          expression: "confirmPassword"
        }
      }),
      _c("v-select", {
        attrs: {
          items: _vm.industries,
          label: "Industry",
          chips: "",
          multiple: ""
        },
        model: {
          value: _vm.company.professional_types,
          callback: function($$v) {
            _vm.$set(_vm.company, "professional_types", $$v)
          },
          expression: "company.professional_types"
        }
      }),
      _vm.otherIsChecked
        ? _c("v-text-field", {
            staticClass: "px-4 pb-4",
            attrs: {
              label: "Please Specify Other Industry",
              "prepend-icon": "fa-industry",
              dense: ""
            },
            model: {
              value: _vm.company.other_professional_type,
              callback: function($$v) {
                _vm.$set(_vm.company, "other_professional_type", $$v)
              },
              expression: "company.other_professional_type"
            }
          })
        : _vm._e(),
      _c("v-text-field", {
        attrs: {
          label: "Company Name",
          "error-messages": _vm.errors.companyName,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "Company Name is required"
            }
          ]
        },
        on: {
          input: _vm.autoGenerateSubdomain,
          blur: function() {
            return _vm.checkForAvailability("subDomain")
          }
        },
        model: {
          value: _vm.company.name,
          callback: function($$v) {
            _vm.$set(_vm.company, "name", $$v)
          },
          expression: "company.name"
        }
      }),
      _c("v-autocomplete", {
        attrs: {
          label: "Currency",
          items: _vm.supportedCurrencies,
          "error-messages": _vm.errors.companyCurrency,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "Currency is required"
            }
          ]
        },
        model: {
          value: _vm.company.currency,
          callback: function($$v) {
            _vm.$set(_vm.company, "currency", $$v)
          },
          expression: "company.currency"
        }
      }),
      _c("vuetify-google-autocomplete", {
        attrs: {
          id: "map",
          label: "Physical Address",
          value: _vm.company.physical_address,
          hint: "Not shown on proposal",
          "error-messages": _vm.errors.physicalAddress,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "Company Address is required"
            }
          ]
        },
        on: { placechanged: _vm.getPhysicalAddressData }
      }),
      _c("v-textarea", {
        attrs: {
          "auto-grow": "",
          outlined: "",
          rows: "1",
          label: "Where Did You Hear About Us",
          "error-messages": _vm.errors.hearAboutUs,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "Please fill out this field"
            }
          ]
        },
        model: {
          value: _vm.company.heard_about_us,
          callback: function($$v) {
            _vm.$set(_vm.company, "heard_about_us", $$v)
          },
          expression: "company.heard_about_us"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: "Account Link",
          "error-messages": _vm.errors.subDomain,
          rules: [
            function(v) {
              return _vm.rules.required(v) || "Please fill out this field"
            },
            function(v) {
              return (
                _vm.rules.subdomain(v) ||
                "Must start with a letter and contain only letters, numbers, and dashes"
              )
            }
          ],
          loading: _vm.subDomainLoading,
          suffix: ".app.curate.co"
        },
        on: {
          blur: function() {
            return _vm.checkForAvailability("subDomain")
          }
        },
        model: {
          value: _vm.company.subdomain,
          callback: function($$v) {
            _vm.$set(_vm.company, "subdomain", $$v)
          },
          expression: "company.subdomain"
        }
      }),
      _c("hidden-submit-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }